import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { DocModuleSearchRequestBody, ExtractedDoc, ExtractedDocResponse } from "../types/types";

export const docModuleApi = createApi({
  reducerPath: "docModuleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/dm",
  }),
  tagTypes: ["DocModule"],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    updateDocModuleData: build.mutation<void, { id: string; content: Partial<ExtractedDoc> }>({
      query(body) {
        return {
          url: "/document",
          method: "PUT",
          body: {
            id: body.id,
            content: body.content,
          },
        };
      },
      invalidatesTags: ["DocModule"],
    }),
    // to add the document to the database; return the uid
    addDocument: build.mutation<{ id: string }, { documentType: string; file: string }>({
      query({ documentType, file }) {
        return {
          url: "/document",
          method: "POST",
          body: {
            documentType,
            file,
          },
        };
      },
      invalidatesTags: ["DocModule"],
    }),
    filterDocuments: build.query<ExtractedDocResponse, DocModuleSearchRequestBody>({
      query: (params) => ({
        url: "/document/search",
        method: "POST",
        body: params,
      }),
      providesTags: ["DocModule"],
    }),
    deleteDocModule: build.mutation<void, { id: string }>({
      query(body) {
        return {
          url: `/document/${body.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DocModule"],
    }),

    // querying file db
    getDocModuleFile: build.query<any, { id: string }>({
      query: (body) => ({
        url: `/file/${body.id}`,
        responseHandler: async (response) => {
          const base64String = await response.text();
          const byteArray = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
          const blob = new Blob([byteArray], { type: "application/pdf" });
          return blob;
        },
      }),
      providesTags: ["DocModule"],
    }),
    getDocModuleXls: build.query<any, { id: string }>({
      query: (body) => ({
        url: `/xls/${body.id}`,
        responseHandler: async (response) => {
          const base64String = await response.text();
          const byteArray = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
          const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
          return blob;
        },
      }),
      providesTags: ["DocModule"],
    }),
  }),
});

export const {
  useAddDocumentMutation,
  useUpdateDocModuleDataMutation,
  useDeleteDocModuleMutation,
  useFilterDocumentsQuery,
  useLazyGetDocModuleXlsQuery,

  // file db
  useLazyGetDocModuleFileQuery,
} = docModuleApi;
